<template>
<div class="row">
    <div v-if="isFound" class="col-lg-12 col-xl-12 col-md-12 col-sm-12 chat-left scroll-bar border-right-light pl-4 pr-4" style="min-height: 100vh">
        <div class="dark-bg px-3 pt-3 pb-0 shadow-xss d-flex d-row" style="width: 100%;">
            <div class="mx-1 mt-0 my-auto">
                <h1><router-link to="/message" style="cursor: pointer" class="border-right"><i class="feather-corner-down-left text-warning mr-3"></i></router-link></h1>
            </div>
            <figure class="avatar ml-3">
                <img v-if="!isLoad" @click="scrollToEnd" :src="opponent.photo ?  opponent.photo : '/images/profile.png'" class="rounded-circle" width="60" alt="Byrom Guittet">
            </figure>
            <div class="mx-3 mt-0 my-auto">
                <div class="my-3" v-if="isLoad">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <h2 v-if="!isLoad" class="text-grey-700">{{opponent.name}}</h2>
                <h4 v-if="!isLoad" class="text-grey-500 font-xssss"><span class="d-inline-block bg-success btn-round-xss m-0"></span> {{opponent.username}}</h4>
            </div>
        </div>
        <div style="height: calc(100vh - 185px);" class="chat-wrapper pt-0 w-100 position-relative scroll-bar" id="chat-wrapper">
            <div class="chat-body p-3 mb-2" v-if="!isLoad">
                <div class="messages-content">
                    <div v-if="messages.more" class="w-100 text-center mb-3"><div style="cursor: pointer" @click="moreMessage" class="text-current">Previous Message</div></div>
                    <div v-for="(message, i) in messages.data.slice().reverse()" :key="i" class="message-item" :class="{'outgoing-message' : message.user_id == user.id}">
                        <div class="message-wrap px-4">
                            <span v-if="message.truncate">
                                {{message.message | truncate(80, '...')}}
                            </span>
                            <span v-else>
                                {{message.message}}
                            </span>
                            <div class="time text-right cursor-pointer" v-if="message.truncate">
                                <a href="javascript:void(0)" @click="untruncate(message.id)" class="cursor-pointer text-primary fw-500">Show more </a>
                            </div>
                            <div class="time text-right mt-2 font-xssss">
                                {{message.created_at | datetime}}
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <div class="chat-bottom dark-bg p-3 shadow-xss" style="width: 98%;">
            <div class="chat-form">
                <!-- <button class="bg-grey float-left"><i class="ti-microphone text-white"></i></button> -->
                <div style="width: calc(100% - 90px);float: left;margin: 0 5px;" class="float-left position-relative">
                    <input style="color: #000000; z-index: 9999; cursor: auto;" type="text" @keyup.enter="sendMessage" v-model="input" placeholder="Start typing.." :disabled="process"/>
                </div>          
                <button @click="sendMessage" class="bg-current"><i class="ti-arrow-right text-white" :disabled="process"></i></button>
            </div>
        </div> 
    </div>
    <div class="col-lg-12" v-if="!isFound">
        <div class="vertical-wrapper pt-lg--7 pt-3" v-if="!isFound">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-8 text-center default-page">
                        <div class="card border-0 text-center d-block">
                            <img :src="'/images/404.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                            <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Page Not Found.</h1>
                            <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p>
                            <button @click="$router.go(-1)" class="p-3 border-0 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'
    

    export default {
        name: "Chat",
        data() {
            return {
                media : process.env.VUE_APP_URL_CLOUD,
                process: false,
                user: {},
                room_id: '',
                opponent:{},
                messages:{
                    data: {},
                    more: false,
                },
                isLoad: true,
                isFound: true,
                dataExist: true,
                page: 1,
                lastId: '',
                input: '',
                idParam: this.$route.params.idUser
            }
        },
        created(){
            this.getUser()
        },
        mounted(){
            this.getMessages()
            this.scrollToEnd()
        },
        beforeDestroy(){
            this.deleteRoom()
        },
        filters:{
            datetime: function (date) {
                return moment(date).calendar();
            }
        },
        methods: {
            untruncate(i) {
                this.messages.data.slice().reverse().map((item) => {
                    if(i == item.id) {
                        item.truncate = false
                    }
                })
            },
            async getUser() {
                this.user = JSON.parse(localStorage.getItem('user'))
            },
            async getMessages() {
                this.process = true
                await axios.get(`${process.env.VUE_APP_URL_API}/chat?lastId=${this.lastId}&username=${this.idParam}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.opponent = res.data.opponent
                    this.room_id = res.data.room_id
                    if (res.data.messages.length === 0) {
                        this.dataExist = false
                    } else {
                        this.dataExist = true
                        if(res.data.messages.data.length) {
                            res.data.messages.data.map((item) => {
                                item.truncate = item.message.length >= 80
                            })
                        }
                        this.messages.data = [...res.data.messages.data]
                        if(this.messages.data.length) {
                            this.lastId = this.messages.data[this.messages.data.length - 1].id
                        }
                        this.messages.more = res.data.messages.more
                        
                        this.scrollToEnd()
                    }
                    this.isLoad = false
                }).catch((err) => {
                    if (err.response.status === 404) {
                        this.isFound = false
                    }
                }).finally(() => this.process = false)
            },
            async moreMessage() {
                if (this.messages.more) {
                    this.process = true
                    this.page += 1
                    await axios.get(`${process.env.VUE_APP_URL_API}/chat?lastId=${this.lastId}&username=${this.idParam}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        if (res.data.messages.length === 0) {
                            this.dataExist = false
                        } else {
                            this.dataExist = true
                            this.messages.more = res.data.messages.more
                            for (let index = 0; index < res.data.messages.data.length; index++) {
                                this.messages.data.push(res.data.messages.data[index])
                            }
                            if(this.messages.data.length) {
                                this.lastId = this.messages.data[this.messages.data.length - 1].id
                            }
                        }
                    }).catch((err) => {
                        if (err.response.status === 404) {
                            this.isFound = false
                        }
                    }).finally(() => this.process = false)
                }
            },
            async sendMessage() {
                if(this.process == false) {
                    this.process = true
                    var data = {
                        room_id: this.room_id,
                        message: this.input
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/chat/send`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(() => {
                        this.input = ''
                    }).catch((err) => console.error(err)).finally(() => this.process = false)
                }
            },
            scrollToEnd() {
                let container = this.$el.querySelector("#chat-wrapper");
                setTimeout(function () {
                    container.scrollTop = container.scrollHeight;
                }, 100);
            },
            pushMessage(data) {
                var datas = {
                    created_at: data.created_at,
                    id: data.id,
                    message: data.message,
                    room_id: data.room_id,
                    updated_at: data.created_at,
                    user_id: data.user_id,
                    truncate: data.message.length >= 80
                }
                this.messages.data.unshift(datas)
                this.scrollToEnd()
            },
            async deleteRoom() {
                if(this.messages.data.length == 0){
                    var data = {
                        room_id : this.room_id,
                        _method : 'delete'
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/chat/room`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).catch((err) => console.error(err.response))
                }
                
            }
        },  
    }
</script>
